import React, { useState, useEffect, useContext } from "react";
import { getLabelText } from '../../functions/functions';
import { apiUrl, token } from '../../App';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { binIcon, checkIcon, circleIcon, closeIcon, dragIcon, okIcon, penIcon,plusIcon } from "../icons/icons";
import { EventInfoHeader } from "../navs/Headers";

// komponent som visar info om personal för ett event
export function Setlist({ selectedEventId, projectData, projectID, artistID }) {
  const [showForm, setShowForm] = useState(false)
  const [isEditing, setIsEditing] = useState(false);
  const [editRight, setEditRight] = useState(localStorage.getItem("editRight"));
  const [isAdding, setIsAdding] = useState(false);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [currentSetlistId, setCurrentSetlistId] = useState("")
  const [selectedSongs, setSelectedSongs] = useState([]);
  const [newSong, setNewSong] = useState({ id: "", title: '', minutes: '0', seconds: '0' })
  const [newSetlist, setNewSetlist] = useState("")
  const [setLists, setSetLists] = useState([])
  const [projects, setProjects] = useState([])
  const [projectSetLists, setProjectSetLists] = useState([])
  const [songData, setSongData] = useState([])
  const [newSetlistProject, setnewSetlistProject] = useState([])
  const [focused, setFocused] = React.useState(false)
const onFocus = () => setFocused(true)
const onBlur = () => setFocused(false)
  const [groupedSongs, setGroupedSongs] = useState({
    included: [],
    encore: [],
    notIncluded: []
  })
  const [suggestions, setSuggestions] = useState([])
  let projectId;
  if (projectID) {
    projectId = projectID
  } else {
    projectId = ""
  }

  let eventId;
  if (selectedEventId) {
    eventId = selectedEventId.toLowerCase()
  } else {
    eventId = localStorage.getItem("selectedEventId").toLowerCase()
  }

  async function fetchSetlists() {
    try {
      const response = await fetch(`${apiUrl}/setlist?id=${eventId}&token=${token}`);
      const data = await response.json();
      console.log(data)

      const eventSetLists = data.filter(setlist => setlist.EvtID == eventId)
      const projectSetLists = data.filter(setlist => setlist.ProjectID == projectId)
      console.log(eventSetLists)
      console.log(projectSetLists)
      setSetLists(eventSetLists)
      if(data.length > 0)
      setCurrentSetlistId(data[0].ID)
    setIsEditing(true)
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  async function fetchProjects() {
    try {
      const response = await fetch(`${apiUrl}/Projectbyproduction?id=${localStorage.getItem("selectedArtistId")}&token=${token}`);
      const data = await response.json();
      console.log(data)
      setProjects(data)

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
 
  async function fetchProjectSetlists(id) {
    console.log(id)
    setnewSetlistProject(id)
    try {
      const response = await fetch(`${apiUrl}/setlist?id=${id}&token=${token}&project=true`);
      const data = await response.json();
      console.log(data)
      setProjectSetLists(data)
      

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchSetlists()
    fetchProjects()
  }, []);
  useEffect(() => {
    var test = "test"
  }, [groupedSongs]);
  useEffect(() => {
    console.log(songData)

    const songs = songData || []

    const grouped = songs.reduce((groups, song) => {
      if (song.Status === "0") {
        groups.included[song.Sort] = (song)
      } else if (song.Status === "1") {
        groups.encore[song.Sort]=(song)
      } else if (song.Status === "2") {
        groups.notIncluded[song.Sort]=(song)
      } else {
        groups.notIncluded[song.Sort]=(song)
      }

      return groups
    }, {
      included: [],
      encore: [],
      notIncluded: []
    })

    

    setGroupedSongs(grouped)

  }, [songData, setLists]);
  function handlepicked(id){
    
    
    var song = suggestions.find(x=>x.ID == id)
    
    setNewSong(prevSong => ({ ...prevSong, title: song.Name ,id:song.ID,minutes:song.Duration.split(":")[0],seconds:song.Duration.split(":")[1]}))
  }
  function handleInputChange(event) {
  

    fetchSongs(event.target.value)
    


    const { name, value } = event.target
    setNewSong(prevSong => ({ ...prevSong, [name]: value }))
  }
  async function fetchSongs(p) {
    try {
      const response = await fetch(`${apiUrl}/setlistSong?p=${p}&token=${token}`);
      const data = await response.json();
      console.log(data)
      setSuggestions(data)

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  function handleSetlistInputChange(event) {
    const { value } = event.target
    setNewSetlist(value)
   
  }

  // funktion för att lägga till låt
  async function handleAddSong() {
    if (newSong.title.trim() && newSong.minutes.trim() && newSong.seconds.trim()) {

      if (parseInt(newSong.seconds) > 59) {
        newSong.seconds = "59"
      }

      const duration = `${newSong.minutes}:${String(newSong.seconds).padStart(2, '0')}`

      const newSongEntry = {
        ID: "",
        Name: newSong.title,
        Duration: duration,
        Status: "2",
        Sort:groupedSongs.notIncluded.length
      }

      setNewSong({ title: '', minutes: '0', seconds: '0' })
      postData(newSongEntry).then((response) => {
        newSongEntry.ID = response,
          setGroupedSongs(prev => {
            const updated = { ...prev }

            updated.notIncluded.push(newSongEntry)
            console.log(updated)

            return updated
          })
      })
    } else {
      alert('Please fill in both fields')
    }
  }

  // funktion för att flytta ordning på låt
  function onDragEnd(result) {
    console.log(result)
    const { source, destination } = result
    if (!destination || (source.droppableId === destination.droppableID && source.index === destination.index)) {
      return;
    }

    setGroupedSongs(prev => {
      const updated = { ...prev }
      const [movedSong] = updated[source.droppableId].splice(source.index, 1)

      if (destination.droppableId === 'included') {
        movedSong.Status = '0'
      } else if (destination.droppableId === 'encore') {
        movedSong.Status = '1'
      } else if (destination.droppableId === 'notIncluded') {
        movedSong.Status = '2'
      }
      // movedSong.Sort = destination.index 
 
      updated[destination.droppableId].splice(destination.index, 0, movedSong)
     console.log(destination.index)
      return updated
    })
    let updatedsong = groupedSongs.included.concat(groupedSongs.encore).concat(groupedSongs.notIncluded).find(x => x.ID == result.draggableId)
    let groupedSongsUpdated = groupedSongs
 

    groupedSongsUpdated.encore.forEach(song => {
      song.Sort = groupedSongsUpdated.encore.indexOf(song)
    })
    groupedSongsUpdated.included.forEach(song => {
      song.Sort = groupedSongsUpdated.included.indexOf(song)
    })
    groupedSongsUpdated.notIncluded.forEach(song => {
      song.Sort = groupedSongsUpdated.notIncluded.indexOf(song)
    })
    setGroupedSongs(groupedSongsUpdated)
    putData(groupedSongs.included.concat(groupedSongs.encore).concat(groupedSongs.notIncluded))
  }

  // funktion för att räkna ut total längd på set
  function calculateDurationSum() {
   
    let totalMinutes = 0
    let totalSeconds = 0

    const songsToCalculate = [...groupedSongs.included, ...groupedSongs.encore]
    songsToCalculate.forEach(song => {
      const [minutes, seconds] = song.Duration.split(":").map(Number)
      totalMinutes += minutes
      totalSeconds += seconds
    })

    totalMinutes += Math.floor(totalSeconds / 60)
    totalSeconds = totalSeconds % 60

    return { totalMinutes, totalSeconds }
  }

  function formatTime(totalMinutes, totalSeconds) {
    const hours = Math.floor(totalMinutes / 60)
    const minutes = totalMinutes % 60
    if (hours > 0) {
      return `${hours}h ${minutes}m`
    } else {
      return `${minutes}m ${totalSeconds}s`
    }
  }

  const { totalMinutes, totalSeconds } = calculateDurationSum()

  // listan på låtarna
  const ListItems = () => {
    return (
      <div className="personel-edit">
        {['included', 'encore', 'notIncluded'].map(status => (
          <div key={status} className="setlist-status-section">
            {groupedSongs[status].length > 0 && (
              <h3 className="status-title">
                {status === 'included' ? '' :
                  status === 'encore' ? getLabelText('app_setlist_Encore') :
                    status === 'notIncluded' ? getLabelText('app_setlist_NotIncluded') : ''}
              </h3>
            )}
            {groupedSongs[status].map((song, index) => {
              const displayIndex = status === 'encore' ? index + groupedSongs['included'].length : index;
              return (
                <div key={song.ID.toString()}>
                  <div className='setlist-item'>
                    <div className={status}>
                      {(status === 'included' || status === 'encore') && <h3>{displayIndex + 1}.</h3>}
                      <h3>{song.Name}</h3>
                    </div>
                    <p>{song.Duration}</p>
                  </div>
                </div>
              )
            })}
            {status === 'encore' && <p className="song-duration">{`${getLabelText('app_setlist_SetLength')} ${formatTime(totalMinutes, totalSeconds)}`}</p>}
          </div>
        ))}
      </div>
    );
  }

  useEffect(() => {
    console.log(currentSetlistId)

    const selectedSetlist = setLists.find(setlist => setlist.ID === currentSetlistId)
    if (selectedSetlist) {
      setSongData(selectedSetlist.Songs)
    }

  }, [currentSetlistId, setLists])

  function toggleSelectedSong(id) {
    setSelectedSongs(prevSelected => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter(songId => songId !== id)
      } else {
        return [...prevSelected, id]
      }
    })
  }

  const Checkbox = ({ id, isSelected, onToggle }) => {
    if (showCheckbox) {
      return (
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => onToggle(id)}
        />
      )
    } else return null
  }

  // funktion för att ta bort låtar
  // function handleDeleteSelectedSongs() {
  //   selectedSongs.forEach(s => {
  //     deleteData(s)
  //   })
  //   setSongData(prevSongs => prevSongs.filter(song => !selectedSongs.includes(song.ID)))
  //   setSelectedSongs([])
  //   console.log(songData)
  // }

  // edit mode
  useEffect(() => {
    console.log(currentSetlistId)

    const selectedSetlist = setLists.find(setlist => setlist.ID === currentSetlistId)
    if (selectedSetlist) {
      setSongData(selectedSetlist.Songs)
    }

  }, [currentSetlistId, setLists])

  const ListEdit = () => {
    return (
      <div className="personel-edit">
        <DragDropContext onDragEnd={onDragEnd}>
          {['included', 'encore', 'notIncluded'].map(status => (
            <Droppable key={status} droppableId={status} direction="vertical">
              {(provided) => (
                <div className="status-section" {...provided.droppableProps} ref={provided.innerRef}>
                  <h3>{status === 'included' ? 'Set' :
                    status === 'encore' ? getLabelText('app_setlist_Encore') :
                      status === 'notIncluded' ? getLabelText('app_setlist_NotIncluded') : ''}</h3>
                  {groupedSongs[status].map((song, index) => {
                    const displayIndex = status === 'encore' ? index + groupedSongs['included'].length : index;
                    return (
                      <Draggable key={song.ID.toString()} draggableId={song.ID.toString()} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div className="setlist">
                              <div className='setlist-edit-item'>
                                <div className={status}>
                                  {(status === 'included' || status === 'encore') && <h3>{displayIndex + 1}.</h3>}
                                  <h3>{song.Name}</h3>
                                </div>
                                <div className="song-end">
                                  <p>{song.Duration}</p>
                                  {dragIcon}
                                </div>
                              </div>
                              <button style={{backgroundColor:"transparent"}}  onClick={()=>{deleteData(song.ID)}}>{binIcon}</button> 
                              {/* <Checkbox
                                id={song.ID}
                                isSelected={selectedSongs.includes(song.ID)}
                                onToggle={toggleSelectedSong} /> */}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                  {status === 'encore' && <p className="song-duration">{`${getLabelText('app_setlist_SetLength')} ${formatTime(totalMinutes, totalSeconds)}`}</p>}
                </div>
              )}
            </Droppable>
          ))}
        </DragDropContext>

      </div>
    );
  };

  // funktion för att posta låtar till API:t
  async function postData(song) {

    try {
      const response = await fetch(`${apiUrl}/setlistSong?token=${token}&setlistid=${currentSetlistId}`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(
          song
        ),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Could not update list.');
      }
      else {
        console.log("Data succesfully updated");
      }
      return data
    } catch (error) {
      console.log('Error updating data', error)
    }

  }
  async function putData(song) {

    // console.log(song)
    // console.log("setLists:", setLists)
    // console.log("songData:", songData)
    // console.log("setlist id:", currentSetlistId)
    // console.log("project id:", projectId)

    try {
      const response = await fetch(`${apiUrl}/setlistSong?token=${token}&setlistid=${currentSetlistId}`, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(
          song
        ),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Could not update list.');
      }
      else {
        console.log("Data succesfully updated");
      }
      return data
    } catch (error) {
      console.log('Error updating data', error)
    }

  }
  async function postNewSetlist(copy) {
    setIsAdding(false)
    let url = `${apiUrl}/setlist?token=${token}`

    if (copy) {
      url = `${apiUrl}/setlist?token=${token}&id=${currentSetlistId}`
    }

    console.log(url)

    console.log({
      EvtID: eventId,
      Name: newSetlist,
      ID: "",
      ProjectID: newSetlistProject
    })

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(
          {
            EvtID: eventId,
            Name: newSetlist,
            ID: "",
            ProjectID: newSetlistProject
          }
        ),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Could not update list.');
      }
      else {
        let newSetlists = setLists
        newSetlists.push(data)
        setCurrentSetlistId(data.ID)
setIsEditing(true)
        console.log(data)
        console.log(newSetlists)
        // setSetLists()

        console.log("Data succesfully updated");
      }
      return data
    } catch (error) {
      console.log('Error updating data', error)
    }
  }
  async function deleteSetList(id) {
    try {
      const response = await fetch(`${apiUrl}/setlist?id=${id}&token=${token}`, {
        method: 'DELETE'
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Could not delete setlist.');
      }
      else {
        setCurrentSetlistId("")
        fetchSetlists()
        console.log("Data succesfully updated");
        
      }

    } catch (error) {
      console.log('Error updating data', error)
    }
  }
  // funktion för att radera låtar
  async function deleteData(song) {
    try {
      const response = await fetch(`${apiUrl}/setlistSong?id=${song}&token=${token}`, {
        method: 'DELETE'
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Could not delete song.');
      }
      else {
        console.log("Data succesfully updated");
        var newArray = groupedSongs
        
        if(newArray.encore)
        newArray.encore = newArray.encore.filter(function(s) { 
          return s.ID !== song
      });
      if(newArray.included)
      newArray.included = newArray.included.filter(function(s) { 
        return s.ID !== song
    });
    if(newArray.notIncluded)
    newArray.notIncluded= newArray.notIncluded.filter(function(s) { 
      return s.ID !== song
  });
  
  console.log(newArray)
        setGroupedSongs(newArray);
        handleSave()
        setIsEditing(true)
   
      }

    } catch (error) {
      console.log('Error updating data', error)
    }
  }

  async function handleSave() {
    setIsEditing(false);
    setShowForm(false)
    setShowCheckbox(false)
  }

  function handleCancel() {
    setIsEditing(false);
    setShowCheckbox(false)
    setShowForm(false)
  }


  return (
    <>
      <EventInfoHeader />
      <div className='desktop-info'>
        <div className="list">
          <div className="list-title">
            {/* {isEditing ? (
              <div className="setlist-title-buttons">
                <button onClick={handleSave}>
                  {checkIcon}
                </button>
                <button onClick={handleCancel}>
                  {closeIcon}
                </button>
              </div>
            ) : (
              <>
                <button onClick={() => setIsEditing(true)}>{penIcon}</button>
              </>
            )} */}
             {currentSetlistId && editRight >= 3? (
             <button onClick={()=>{deleteSetList(currentSetlistId)}}>{binIcon}</button>
            ) : (
              <>
               
              </>
            )}
            
            <h1>{getLabelText('app_eventinfo_Setlist')}</h1>
         
            {isAdding ? (
              <>
              </>
            ) : (
              
              <div className="row">
          
                <select style={{marginRight:"5px"}} className="form-control col " value={currentSetlistId} onChange={e => setCurrentSetlistId(e.target.value)}>
                  <option value=""> Select a setlist</option>
                  {setLists.map((setlist, index) => (
                    <option value={setlist.ID} key={setlist.ID}>
                      {setlist.Name}
                    </option>
                  ))}
                </select>
                {editRight >= 3 ?  <button className="col-auto" onClick={() => setIsAdding(!isAdding)}>{plusIcon}</button>: <></>}
               
              </div>
            )}
            {editRight >=3  ? (
              <div className="setlist-title-buttons">
                {selectedSongs.length > 0 && <button onClick={handleDeleteSelectedSongs}>{okIcon}</button>}
               
              </div>
            ) : (
              <>
              </>
            )}
          </div>
          {editRight >=3  ? (
          (isAdding ? ( <div className="personel-edit">
            <h2>Add setlist</h2>
            <input className="setlist-input form-control"
              type="text"
              placeholder={getLabelText('t_Name')}
              name="title"
              value={newSetlist}
              onChange={handleSetlistInputChange}
            />
            <div className="addsetlist-input ">

              <select className="form-control mt-2" onChange={e => fetchProjectSetlists(e.target.value)}>
                <option value=""> Select a project</option>
                {projects.map((project, index) => (
                  <option value={project.ID} key={project.ID}>
                    {project.Name}
                  </option>
                ))}
              </select>
              <select className="form-control mt-2" value={currentSetlistId} onChange={e => setCurrentSetlistId(e.target.value)}>
                <option value=""> Select a setlist</option>
                {projectSetLists.map((setlist, index) => (
                  <option value={setlist.ID} key={setlist.ID}>
                    {setlist.Name}
                  </option>
                ))}
              </select>
              <div className="mt-2">
                <button className="confirm-button" onClick={postNewSetlist}>Skapa ny</button>
                <button className="copy-button" onClick={() => postNewSetlist(true)}>Kopiera</button>
                <button className="cancel-button" onClick={() => setIsAdding(false)}>Avbryt</button>
              </div>
            </div>

          </div>): 
            <>
              <ListEdit />
              {showForm ? (
                <div className="personel-edit">
                  <input className="setlist-input"
                    type="text"
                    placeholder={getLabelText('t_Title')}
                    name="title"
                    value={newSong.title}
                    onChange={ handleInputChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                  />
                  {suggestions.length > 0 && focused &&(
                    <ol className="suggestions-list">
                       {suggestions.map((suggestion, index) => (
                  <li  key={suggestion.ID} onMouseDown={()=>handlepicked(suggestion.ID)}>
                      <b>{suggestion.Name}</b>   <small>{suggestion.Duration}</small>
                  </li>
                ))}
         
                    </ol>
                      )}
                  <div className="duration-input">
                    <div>

                    <label>min</label>
                    <input className="setlist-input"
                      type="number"
                      placeholder="min"
                      name="minutes"
                      value={newSong.minutes}
                      onChange={handleInputChange}
                      min='0'
                    />
                    </div>
                    <div >

                    <label>sec</label>
                    <input className="setlist-input"
                      type="number"
                      placeholder="sec"
                      name="seconds"
                      value={newSong.seconds}
                      onChange={handleInputChange}
                      min='0'
                      max='59'
                    />
                    </div>
                
                   
                  
                   
                  </div>
                  <button className="confirm-button" onClick={handleAddSong}>{getLabelText('app_setlist_Confirm')}</button>
                  

                  <button className="cancel-button" onClick={() => setShowForm(!showForm)}>X</button>
                </div>
              ) : (
                <div className="personel-edit">
                  <button onClick={() => setShowForm(!showForm)}>{getLabelText('app_setlist_AddSong')}</button>
                </div>
              )}
            </>
)
          ) : 

           
          (
            <ListItems />
          )}
        </div>
      </div>
    </>
  );
}