import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Route } from 'react-router';
import { ArtistContextProvider, EventContextProvider } from './components/contexts/Context';
import { Home } from './components/main/Home';
import { Calendar } from './components/main/Calendar';
import { EventList } from './components/main/EventList';
import { EventInfo } from './components/eventinfo/EventInfo';
import { EventInfoblad } from './components/eventinfo/EventInfoblad';
import { EventDetails } from './components/eventinfo/EventDetails';
import { Login } from './components/main/Login';
import { Settings } from './components/main/Settings';
import { MyDatePicker } from './components/misc/DatePicker';
import { NavMenu } from './components/navs/NavMenu';
import { DayScheme } from './components/eventinfo/DayScheme';
import { TourInfo } from './components/main/TourInfo';
import { PersonelInfo } from './components/eventinfo/PersonelInfo';
import { Guestlist } from './components/eventinfo/Guestlist';
import { ApprovedGuests } from './components/eventinfo/ApprovedGuests';
import { Setlist } from './components/eventinfo/Setlist';
import { PersonInfo } from './components/main/PersonInfo';
import { EventEdit } from './components/eventinfo/EventEdit';
import { handleLogout } from './functions/functions';

import './styles/bslight.css'
import './styles/dark-mode.css'
import './styles/custom.css'
import './styles/typography.css'
import './styles/nav-menu.css'
import './styles/event-list.css'
import './styles/event-info.css'
import './styles/calendar.css'
import './styles/datepicker.css'
import './styles/settings.css'
import './styles/form.css'
import './styles/viewport.css'

// sätter konstanter som används på flera ställen
  export const apiUrl = "https://old-ustage.azurewebsites.net/api/v2"
//  export const apiUrl = "http://localhost:49306/api/v2"
export let token = window.localStorage.getItem("token")
export const desktopBreakpoint = 768;

// huvudsida som visar de olika komponenterna via routes
function App() {
  const location = useLocation();
  const [theme, setTheme] = useState('light-mode');
  const [loading, setLoading] = useState(false);
  // const [language, setLanguage] = useState('en-GB');
  // const [dateFormat, setDateFormat] = useState('YYYY-MM-DD')
  // const [timeFormat, setTimeFormat] = useState('24H')
  const [tokenValid, setTokenValid] = useState(false);

  // funktion som kollar om token är OK
   async function fetchTokenCheck() {
    // alert(localStorage.getItem("login"))
    
    token = window.localStorage.getItem("token")
   
     

   if(localStorage.getItem("login") != "loading" && token.length !== 0){
    
      
    try {
      const response = await fetch(`${apiUrl}/test?token=${token}`);
      localStorage.setItem("login", "new")
      if (response.status === 403) {
        handleLogout()
      } else if (response.statusText === "OK") {
        setTokenValid(true);
      } else {
        
        handleLogout()
      }
    } catch (error) {
      console.error('Error checking token', error)
    }
   }else{
    
    setTimeout(() => {
      fetchTokenCheck()
    }, 1000);
   }
   
  }

  // omredigerar till loginsidan om token inte finns
  useEffect(() => {
   
    if (location.pathname.includes("approvedguests")) {
      console.log(location.pathname)
    } else if (!token && !window.location.href.includes("token")) {
      
      let loginUrl = window.location.href
      loginUrl = loginUrl.substring(0, loginUrl.indexOf("/", 10))
      loginUrl += "/login"
    
      window.location.href = (`https://login.ustage.se/?loginpage=${loginUrl}&refpage=${window.location.pathname}`);
    } else{
      
      fetchTokenCheck();
    }
  },[tokenValid]);

  // sätter eventID i localStorage från URL på vissa sidor
  if (location.pathname.includes("event/") || location.pathname.includes("infoblad/") || location.pathname.includes("eventdetails/") || location.pathname.includes("venue/") || location.pathname.includes("travelinfo/") || location.pathname.includes("hotelinfo/") || location.pathname.includes("dayscheme/") || location.pathname.includes("personel/") || location.pathname.includes("setlist/") || location.pathname.includes("guestlist/") || location.pathname.includes("approvedguests/")) {
    const selectedEventId = location.pathname.slice(-36);
    localStorage.setItem("selectedEventId", selectedEventId);
    console.log(location.pathname)
  }

  // sätter artistID localStorage från URL på vissa sidor
  if (location.pathname.includes("events/") || location.pathname.includes("calendar/") || location.pathname.includes("tour/")) {
    const selectedArtistId = location.pathname.slice(-36);
    if(!selectedArtistId.includes("/"))
    localStorage.setItem("selectedArtistId", selectedArtistId);
  }

  // sätter rätt tema
  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      setTheme(savedTheme);
    }
  }, [theme]);

  // funktion som hanterar ändring av valt språk
  function handleLanguageChange(newLanguage) {
    localStorage.setItem('language', newLanguage);
  };

  // funktion som hanterar ändring av valt tema
  function handleThemeChange(newTheme) {
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  // funktion som hanterar ändring av valt datumformat
  function handleDateFormatChange(newDateFormat) {
    localStorage.setItem('DateFormat', newDateFormat);
  };

  // funktion som hanterar ändring av valt tidformat
  function handleTimeFormatChange(newTimeFormat) {
    localStorage.setItem('TimeFormat', newTimeFormat);
  };

  if (location.pathname.includes("approvedguests")) {
    return (
      <main className={theme}>
        <Route path='/approvedguests' component={ApprovedGuests} />
      </main>
    )
  } else if (!tokenValid) {
    console.log("fel")
      return <Route exact path='/login' component={Login} />
    
    
  } else {
    console.log("rätt")
    return (
      <ArtistContextProvider>
        <EventContextProvider>
          <main className={theme}>
            <NavMenu />
            <Route exact path='/' component={Home} />
            <Route path='/login' component={Login} />
            <Route path='/events' component={EventList} />
            <Route path='/event/' component={EventInfo} />
            <Route path='/tour' component={TourInfo} />
            <Route path='/infoblad' component={EventInfoblad} />
            <Route path='/eventdetails' component={EventDetails} />
            <Route path='/event-edit' component={EventEdit} />
            <Route path='/travelinfo' component={EventDetails} />
            <Route path='/hotelinfo' component={EventDetails} />
            <Route path='/dayscheme' component={DayScheme} />
            <Route path='/venue' component={EventDetails} />
            <Route path='/personel' component={PersonelInfo} />
            <Route path='/guestlist' component={Guestlist} />
            <Route path='/setlist' component={Setlist} />
            <Route path='/person/:id' component={PersonInfo} />
            <Route path='/calendar' component={Calendar} />
            <Route path='/settings'>
              <Settings onThemeChange={handleThemeChange} onLanguageChange={handleLanguageChange} onDateFormatChange={handleDateFormatChange} onTimeFormatChange={handleTimeFormatChange} />
            </Route>
            <Route path='/datepicker' component={MyDatePicker} />
          </main>
        </EventContextProvider>
      </ArtistContextProvider>
    );
  }
}

export default App;