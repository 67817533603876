import { useState, useEffect } from 'react';
import { apiUrl, token } from '../App';
import enLocale from '@fullcalendar/core/locales/en-gb';
import svLocale from '@fullcalendar/core/locales/sv';
import nbLocale from '@fullcalendar/core/locales/nb';
import daLocale from '@fullcalendar/core/locales/da';
import csLocale from '@fullcalendar/core/locales/cs';

// funktion som hanterar ändring i fönsterstorlek
export function useWindowResize() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return windowWidth;
};

// funktion som hanterar utloggning
export function handleLogout() {
    
    window.localStorage.removeItem("token");
    window.location.href = 'https://login.ustage.se/logout?loginpage='+ window.location.origin+ "/login"
    // window.location.href = 'https://localhost:44312/logout'
}

// funktion som kollar om token är ok
export async function checkToken() {
    try {
        const response = await fetch(`${apiUrl}/Productions?token=${token}`);

        // if (response.status === 403) {
        //     handleLogout()
        // }
        // else
        if (response.statusText === "OK") {
            console.log("Token is", response.statusText)
        }
        else {
            // alert(response.status + " " + response.statusText)
            handleLogout()
        }
    } catch (error) {
        console.error('Failed to check token', error);
    }
}

// funktioner som formaterar datum

export function formatEventDate(dateString) {
    const date = new Date(dateString);
    let lang = localStorage.getItem("language")
    
    if (lang === "nn-NO") {
     lang = "nb-NO"   
    }

    let options = {
        weekday: 'short',
        month: 'short',
        day: 'numeric'
    };

    return date.toLocaleDateString(lang, options).toUpperCase();
};

export function formatHeaderDate(dateString) {
    const date = new Date(dateString);
    const lang = localStorage.getItem("language")

    let dateOptions = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };

    return date.toLocaleDateString(lang, dateOptions).toUpperCase()
}

export function formatDate2(dateString) {
    const date = new Date(dateString);
    const dateFormat = localStorage.getItem("DateFormat")
    const timeFormat = localStorage.getItem("TimeFormat")

    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, 0)
    const day = String(date.getDate()).padStart(2, 0)

    const timeOptions = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: timeFormat === "12",
    };

    const formattedTime = date.toLocaleTimeString(undefined, timeOptions)

    if (dateFormat === 'YYYY-MM-DD') {
        return `${year}-${month}-${day} ${formattedTime}`;
    } else if (dateFormat === 'MM/DD/YYYY') {
        return `${month}/${day}/${year} ${formattedTime}`;
    } else if (dateFormat === 'DD/MM/YYYY') {
        return `${day}/${month}/${year} ${formattedTime}`;
    } else {
        throw new Error('Invalid format specifier');
    }
}

// funktion som översätter text från ordlistan beroende på valt språk
export function getLabelText(label) {

    let data = JSON.parse(localStorage.getItem("dictionary"))
// console.log(data)
    if (data) {
        if (data.Message === "An error has occurred.") {
        }
        let cultureCode = localStorage.getItem('language')

        for (let i = 0; i < data.length; i++) {
            if (data[i].cultureCode === cultureCode) {
                for (let j = 0; j < data[i].labels.length; j++) {
                    if (data[i].labels[j].Label === label) {
                        if (data[i].labels[j].Text === "") {
                            return label
                        }
                        return data[i].labels[j].Text;
                    }
                }
            }
        }


    } else {
        console.log("EJ", label)
        console.log(data)

    }
}

// funktion som sätter rätt språk i kalendern och dagschemat
export function setCalendarLanguage(setLanguage) {
    let lang = localStorage.getItem('language');

    if (lang === "en-GB") {
        setLanguage(enLocale)
    } else if (lang === "sv-SE") {
        setLanguage(svLocale)
    } else if (lang === "nn-NO") {
        setLanguage(nbLocale)
    } else if (lang === "da-DK") {
        setLanguage(daLocale)
    } else if (lang === "cs-CZ") {
        setLanguage(csLocale)
    }
}

// funktion för att krympa/förstora komponent
export function handleFold(state, setState) {
    setState(!state);
};

// funktion för att stänga ner komponent
export function handleClose(setState) {
    setState(false);
};

// funktion som ändrar fold ikonen beroende på om den är öppen eller stängd
export function getArrowIcon(state, foldIcon, expandIcon) {
    return state ? foldIcon : expandIcon;
};

// funktion för att öppna/stänga iCal modulen
export function handleICalIconClick(state, setState) {
    setState(!state);
};

// funktion som visar meddelande vid olika komponenter
export function showMessage(setMessage, setIsMessageVisible, msg) {
    setMessage(msg);
    setIsMessageVisible(true);
    setTimeout(() => setIsMessageVisible(false), 2500);
};

// Laddningsindikatorer
export const LargePlaceholder = () => {
    return (
        <>
            <span className="placeholder col-12 placeholder-lg"></span>
            <span className="placeholder col-12 placeholder-lg"></span>
            <span className="placeholder col-12 placeholder-lg"></span>
            <span className="placeholder col-12 placeholder-lg"></span>
            <span className="placeholder col-12 placeholder-lg"></span>
        </>
    )
}

export const Placeholder = () => {
    return (
        <>
            <p className="card-text placeholder-glow">
                <span className="placeholder col-12 placeholder-sm"></span>
                <span className="placeholder col-12 placeholder-sm"></span>
                <span className="placeholder col-12 placeholder-sm"></span>
                <span className="placeholder col-12 placeholder-sm"></span>
                <span className="placeholder col-12 placeholder-sm"></span>
            </p>
        </>
    )
}

export const SmallPlaceholder = () => {
    return (
        <>
            <p className="card-text placeholder-glow">
                <span className="placeholder col-12 placeholder-xs"></span>
                <span className="placeholder col-12 placeholder-xs"></span>
                <span className="placeholder col-12 placeholder-xs"></span>
                <span className="placeholder col-12 placeholder-xs"></span>
            </p>
        </>
    )
}